<template>
  <LayoutDefault
    :main-footer="true"
    :main-sidebar="false"
    :header-session-nav="false"
    :dark="false"
    :full="true"
    :force-extended-app-logo="true"
  >
    <template #header-toolbar>
      <HeaderLanguagePanel />
    </template>

    <template #main-content>
      <div class="centered-container">
        <div class="w-80">
          <!-- Feedback icons -->
          <div
            class="rounded-full mx-auto w-16 h-16 flex items-center justify-center m-5"
            :class="{ 'bg-primary': !hasErrors, 'bg-red-600': !validating && hasErrors, 'bg-gray-500': validating }"
          >
            <fw-icon-check v-if="!validating && !hasErrors" class="fill-current h-8 w-8 text-white" />
            <fw-icon-loading v-else-if="validating" class="fill-current h-8 w-8 text-white" />
            <fw-icon-close v-else class="fill-current h-8 w-8 text-white" />
          </div>

          <fw-heading v-if="hasErrors && !validating" size="h3" class="text-center mb-4 text-red-600">{{
            validateError === 'InvalidActivateToken' ? $t('errorInvalidToken') : $t('errorUnknowError')
          }}</fw-heading>
          <fw-heading v-else-if="validating" size="h3" class="text-center mb-4 text-gray-500">{{
            $t('messageValidatingAccount')
          }}</fw-heading>
          <fw-heading v-else size="h3" class="text-center mb-4 text-primary">{{
            $t('messageConfirmedAccount')
          }}</fw-heading>

          <div v-if="!validating && !hasErrors">
            <div class="mb-5 text-center text-sm">{{ $t('messageCanLoginNow') }}</div>
            <fw-button type="primary" expanded size="sm" @click.native="$router.push({ name: 'login' })">{{
              $t('goToLogin')
            }}</fw-button>
          </div>
          <div v-else class="mt-5 text-center">
            <fw-button type="link-muted" size="sm" @click.native="$router.push({ name: 'login' })">{{
              $t('backToLogin')
            }}</fw-button>
          </div>
        </div>
      </div>
    </template>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDefault'
import ServiceAuth from '@/fw-modules/fw-core-vue/id/services/ServiceAuth'
import HeaderLanguagePanel from '@/fw-modules/fw-core-vue/interface/components/header/HeaderLanguagePanel'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  name: 'ExternalAccountConfirm',

  components: {
    LayoutDefault,
    HeaderLanguagePanel,
  },

  data() {
    return {
      validating: true,
      validateError: '',
      localUser: this.$store.getters.getLocalUser,
      supportEmail: 'support@ucframework.pt',
    }
  },

  computed: {
    token() {
      return this.$route.query.t ? this.$route.query.t : null
    },
    hasErrors() {
      return this.validateError !== ''
    },
  },

  created() {
    this.validateEmail()
  },

  methods: {
    async validateEmail() {
      if (this.token !== null) {
        this.validateError = ''
        this.validating = true
        try {
          await ServiceAuth.activateAccount(this.token)
        } catch (error) {
          this.validateError = utils.errors(error).getKey() || 'Undefined'
          console.warn(`login error: ${this.validateError}`, error)
        }
      } else {
        console.error('Unknown token')
        this.validateError = 'Token inexistente'
      }
      setTimeout(() => {
        this.validating = false
      }, 1000)
    },
  },
}
</script>

<i18n>
{
  "en": {
    "errorInvalidToken": "O código não é válido",
    "errorUnknowError": "Erro desconhecido",
    "messageValidatingAccount": "A validar a conta",
    "messageConfirmedAccount": "Conta validada",
    "messageCanLoginNow": "Já pode autenticar-se nesta plataforma",
    "goToLogin": "Entrar na página de autenticação",
    "backToLogin": "voltar à página de autenticação"
  },
  "pt": {
    "errorInvalidToken": "O código não é válido",
    "errorUnknowError": "Erro desconhecido",
    "messageValidatingAccount": "A validar a conta",
    "messageConfirmedAccount": "Conta validada",
    "messageCanLoginNow": "Já pode autenticar-se nesta plataforma",
    "goToLogin": "Entrar na página de autenticação",
    "backToLogin": "voltar à página de autenticação"
  }
}
</i18n>
